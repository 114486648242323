import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'VSWAP',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x8d90928D7b7Ba9Aa9Ca29b9c4b61B311DB5a83c9',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: '0x4028a815f9ed0e0afcfbd7aeb35e2d898302f1da',
    },
    tokenSymbol: 'VSWAP',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x869a6f4Bee6A09221a7dB6F5d04c166342B05a3E',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: contracts.cake[25],
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    token1: 'VSWAP',
    // token2:"MATIC"
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'VSWAP-WCRO LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x8d90928D7b7Ba9Aa9Ca29b9c4b61B311DB5a83c9',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: '0x4028a815f9ed0e0afcfbd7aeb35e2d898302f1da',
    },
    tokenSymbol: 'VSWAP',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x869a6f4Bee6A09221a7dB6F5d04c166342B05a3E',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: contracts.cake[25],
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    token1: 'VSWAP',
    // token2:"MATIC"
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'VSWAP-USDC LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x8d90928D7b7Ba9Aa9Ca29b9c4b61B311DB5a83c9',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: '0x829cc4b2e5a4777a147990f6a5134d1efdf2432c',
    },
    tokenSymbol: 'VSWAP',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x869a6f4Bee6A09221a7dB6F5d04c166342B05a3E',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: contracts.cake[25],
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    token1: 'VSWAP',
    // token2:"MATIC"
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'CRO-USDC LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x8d90928D7b7Ba9Aa9Ca29b9c4b61B311DB5a83c9',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: '0x2d1a949D9F03204C879A135A440cBbBF5d5D562d',
    },
    tokenSymbol: 'CRO',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x869a6f4Bee6A09221a7dB6F5d04c166342B05a3E',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: contracts.wbnb[25],
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    token1: 'VSWAP',
    // token2:"MATIC"
  },
  {
    pid: 4,
    risk: 5,
    lpSymbol: 'CRO-WETH LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x8d90928D7b7Ba9Aa9Ca29b9c4b61B311DB5a83c9',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: '0x210EA586D1131DF5bc18B457B981fBA7A5B53Cc2',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x869a6f4Bee6A09221a7dB6F5d04c166342B05a3E',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    // token1: 'VSWAP',
    // token2:"MATIC"
  },
  {
    pid: 5,
    risk: 5,
    lpSymbol: 'CRO-WBTC LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x8d90928D7b7Ba9Aa9Ca29b9c4b61B311DB5a83c9',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: '0x7637621962Af0a9A9f2AE7E132E35082A84A10e9',
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x869a6f4Bee6A09221a7dB6F5d04c166342B05a3E',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: contracts.wbtc[25],
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    token1: 'VSWAP',
    // token2:"MATIC"
  },
  {
    pid: 6,
    risk: 5,
    lpSymbol: 'USDT-USDC LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x8d90928D7b7Ba9Aa9Ca29b9c4b61B311DB5a83c9',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: '0x65Af1A81AA1a01B608bDB2A0b35FF8113245ee1D',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x869a6f4Bee6A09221a7dB6F5d04c166342B05a3E',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: contracts.usdc[25],
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    token1: 'VSWAP',
    // token2:"MATIC"
  },
  {
    pid: 7,
    risk: 5,
    lpSymbol: 'WETH-WBTC LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x8d90928D7b7Ba9Aa9Ca29b9c4b61B311DB5a83c9',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: '0x10Ba0e8480103626b467Abcd2715ecfaA52C9B97',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x869a6f4Bee6A09221a7dB6F5d04c166342B05a3E',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: contracts.weth[25],
    },
    quoteTokenSymbol: QuoteToken.WBTC,
    quoteTokenAdresses: contracts.wbtc,
    // token2:"MATIC"
  },
  {
    pid: 8,
    risk: 5,
    lpSymbol: 'DAI-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
      137: '0x8d90928D7b7Ba9Aa9Ca29b9c4b61B311DB5a83c9',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: '0x7a24759edaad6b8901a129b3d45830ad0e279225',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '',
      56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
      137: '0x869a6f4Bee6A09221a7dB6F5d04c166342B05a3E',
      80001: '0xa2b09fC8A34961623Aae02a890d383D91B979555',
      25: '0xF2001B145b43032AAF5Ee2884e456CCd805F677D',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
    // token1: 'VSWAP',
    // token2:"MATIC"
  },
]

export default farms
